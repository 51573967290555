import { Spin } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

export const FontStyles = createGlobalStyle`

  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    src: local('Raleway'), url(./fonts/Raleway/Raleway-Regular.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 400;
    src: local('Raleway'), url(./fonts/Raleway/Raleway-italic.ttf) format('truetype');
  }
`;

export const VisualizationApp = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Graph = styled.div`
  display: flex;
  flex: 1;
`;

export const LoadingSpin = styled(Spin)`
  position: relative;
  left: 49%;
  top: 44%;
  transform: scale(1.8);
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
`;

export const LoadingPage = styled.div`
  height: 100%;
`;

export const LoadingTitle = styled.h3`
  color: #579dff;
  position: relative;
  width: 84px;
  left: 46.5%;
  top: 48%;
  font-family: monospace;
`;

export const BubbleTitle = styled.h1`
  color: #fc9438;
  padding-left: 20px;
  padding-top: 15px;
`;
