/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import FloatButtonGroup from '../../components/buttonGroupMenu/buttonGroupMenu';
import copyData from '../../Utils/functions/copyData';
import {
  BubbleTitle,
  Content,
  Graph,
  LoadingPage,
  LoadingSpin,
  LoadingTitle,
  VisualizationApp,
} from '../../styles/global';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import RadialTree from '../../components/radialTree/radialtree';
import getURLParams from '../../Utils/functions/getURLParams';
import api from '../../services/api';

interface Question {
  _children: any[];
  children: any[];
  name: string;
  countPeopleAnswered: number;
  splitedNamePart1: string;
  splitedNamePart2: string;
  countAnswers: number;
}

interface ParamTypes {
  id: string;
  code: string;
}

const BubbleChartPage: React.FC = () => {
  const [urlParams, setUrlParams] = useState(getURLParams());
  const [nTopics, setNtopics] = useState(-1);
  const [data, setData] = useState();
  const [title, setTitle] = useState('');
  const [backupData, setBackupData] = useState();
  const [resetFilters, setResetFilters] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [node, setNode] = useState();
  const [nodeClicked, setNodeClicked] = useState();
  const [answers, setAnswers] = useState({});
  const [showSidebar, setShowSidebar] = useState(false);
  const [filteredData, setFilteredData] = useState<any>([]);
  const { id } = useParams<ParamTypes>();
  const { code } = useParams<ParamTypes>();
  const [isExternalLink, setIsExternalLink] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);

  const getFilteredData = useCallback(async (urlFilterParams: any) => {
    const bubbleDataResponse = await api.post(
      '/cube/bubbleData',
      urlFilterParams,
    );

    setData(bubbleDataResponse.data);
  }, []);

  // get data
  useEffect(() => {
    let mounted = true;
    const getData = async (): Promise<any> => {
      let bubbleDataResponse;
      let dropdownResponse;
      if (!id) {
        setIsExternalLink(false);
        dropdownResponse = await api.post('/cube/bubbleData/filters', {
          screenFilters: urlParams,
          bubbleFilters: ['segment', 'usergroup', 'access_name'],
        });

        bubbleDataResponse = await api.post('/cube/bubbleData', urlParams);
      } else {
        setIsExternalLink(true);
        bubbleDataResponse = await api.post('/cube/bubbleDataByExternalLink', {
          id,
          code,
        });
      }

      if (mounted) {
        if (!id) {
          setIsExternalLink(false);
          setData(bubbleDataResponse.data);
          setBackupData(bubbleDataResponse.data);
          setDropdownOptions(dropdownResponse?.data);
          setFilteredData(bubbleDataResponse.data);
          setShowAnswers(urlParams.showAnswers);
        } else {
          setIsExternalLink(true);
          setTitle(bubbleDataResponse.data.title);
          setData(bubbleDataResponse.data.data);
          setBackupData(bubbleDataResponse.data.data);
          setDropdownOptions(dropdownResponse?.data.data);
          setFilteredData(bubbleDataResponse.data.data);
          if (bubbleDataResponse.data.nTopics) {
            setNtopics(bubbleDataResponse.data.nTopics);
          } else {
            setNtopics(-1);
          }
          setShowAnswers(bubbleDataResponse.data.showAnswers);
        }

        const decodedUrl = decodeURI(window.location.search);
        const queryParameters = new URLSearchParams(decodedUrl);
        const ntopicUrl = queryParameters.getAll('nTopic');
        const showAnswersUrl = queryParameters.getAll('showAnswers');

        if (showAnswersUrl[0]) {
          setShowAnswers(showAnswersUrl[0] === 'true');
        }
        if (ntopicUrl[0]) {
          if (ntopicUrl[0] === '') {
            setNtopics(-1);
          } else {
            setNtopics(parseInt(ntopicUrl[0], 10));
          }
        }

        const paramTitle = queryParameters.getAll('title');
        if (paramTitle[0]) {
          setTitle(paramTitle[0]);
        }
      }
    };
    getData();
    return () => {
      mounted = false;
    };
  }, [code, id, urlParams]);

  useEffect(() => {
    if (resetFilters) {
      setData(copyData(backupData));

      setResetFilters(false);
    }
  }, [backupData, resetFilters]);

  useEffect(() => {
    if (nTopics !== -1) {
      const dataAux: any = copyData(data);
      const questions = dataAux.children;

      const newQuestionList: any = [];

      questions.forEach((question: Question) => {
        const category2List = question._children
          ? question._children
          : question.children;

        const category2Filtered = category2List.map((category2: any) => {
          let countAnswers = 0;

          const category1ListFiltered = category2._children
            ? category2._children
            : category2.children;

          category1ListFiltered.forEach((category1: any) => {
            countAnswers += category1.answers.length;
          });

          const newCategory2 = {
            ...category2,
            count: countAnswers,
            countAnswers: category2.countAnswers,
            children: category2.children,
            _children: category2._children,
          };
          return newCategory2;
        });

        const category2ListSorted = category2Filtered.sort(
          (category2a: any, category2b: any) =>
            category2a.count > category2b.count ? -1 : 1,
        );

        const newCategory2List = category2ListSorted.slice(0, nTopics);

        const category2ListFiltered = newCategory2List.map(
          (categoryLevel2: any) => {
            const groupTopics: any = [];

            // get topics from categories
            const category1List = categoryLevel2._children
              ? categoryLevel2._children
              : categoryLevel2.children;

            // sort by topics that has more answers
            const sorted = category1List.sort(
              (category1a: any, category1b: any) =>
                category1a.answers.length > category1b.answers.length ? -1 : 1,
            );

            const nFirstTopics = sorted.slice(0, nTopics);

            const remainingTopics = sorted.slice(nTopics, sorted.length);

            nFirstTopics.forEach((t: any) => groupTopics.push(t));

            const andereTopic: any = {};
            andereTopic.name = 'Andere';
            andereTopic.answers = [];

            let countAnswersAndere = 0;
            remainingTopics.forEach((ramainingTopic: any) => {
              const andereAnswers = andereTopic.answers;
              countAnswersAndere += ramainingTopic.answers.length;
              ramainingTopic.answers.forEach((answer: any) => {
                const newAnswer: any = {};
                newAnswer.answer = answer.answer;
                newAnswer.originTopic = ramainingTopic.name;
                newAnswer.usergroup = answer.usergroup;
                newAnswer.segment = answer.segment;
                newAnswer.access_name = answer.access_name;
                andereAnswers.push(newAnswer);
              });
            });
            andereTopic.countAnswers = countAnswersAndere;

            if (andereTopic.answers.length > 0) {
              groupTopics.push(andereTopic);
            }

            return {
              name: categoryLevel2.name,
              countAnswers: categoryLevel2.countAnswers,
              splitedNamePart1: categoryLevel2.splitedNamePart1,
              splitedNamePart2: categoryLevel2.splitedNamePart2,
              _children: groupTopics,
            };
          },
        );

        newQuestionList.push({
          name: question.name,
          countPeopleAnswered: question.countPeopleAnswered,
          countAnswers: question.countAnswers,
          splitedNamePart1: question.splitedNamePart1,
          splitedNamePart2: question.splitedNamePart2,
          _children: category2ListFiltered,
        });
      });

      dataAux.children = newQuestionList;
      setFilteredData(dataAux);
    }
  }, [data, nTopics]);

  return (
    <>
      {data ? (
        <>
          <div
            id="header"
            style={{ display: 'flex', flexDirection: 'column', height: '10%' }}
          >
            <BubbleTitle>{title}</BubbleTitle>
            <Breadcrumb showSidebar={showSidebar} node={node}></Breadcrumb>
          </div>
          <div id="bubble" style={{ display: 'flex', height: '90%' }}>
            <div id="svg-div" style={{ display: 'flex', width: '100%' }}>
              <RadialTree
                title={title}
                nodeClicked={nodeClicked}
                setNodeClicked={setNodeClicked}
                data={nTopics === -1 ? data : filteredData}
                setNode={setNode}
                showSideBar={showSidebar}
                setShowSideBar={setShowSidebar}
                answers={answers}
                setAnswers={setAnswers}
                isExternalLink={isExternalLink}
                showAnswers={showAnswers}
              />
            </div>
          </div>
          {!isExternalLink && (
            <FloatButtonGroup
              urlParams={urlParams}
              dropdownOptions={dropdownOptions}
              setNtopics={setNtopics}
              setResetFilters={setResetFilters}
              getFilteredData={getFilteredData}
            ></FloatButtonGroup>
          )}
        </>
      ) : (
        <>
          <LoadingPage id="loadingPage">
            <LoadingSpin size="large"></LoadingSpin>
            <LoadingTitle id="loading-title">Loading...</LoadingTitle>
          </LoadingPage>
        </>
      )}
    </>
  );
};

export default BubbleChartPage;
