import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'antd';
import UserCard from '../card/userCard';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { H2Sidebar, ContainerItem, OffcanvasBody } from './styles';
import PDFContent from './pdfContent';
import { PDFDownloadLink } from '@react-pdf/renderer';

interface props {
  title: any;
  show: boolean;
  handleClose: any;
  node: any;
}

const SideBar: React.FC<props> = ({ show, handleClose, node, title }) => {
  let myArray: any = [];

  let allAnswers: any = [];

  // if node is a leaf
  if ('answers' in node) {
    allAnswers = node.answers;
    myArray = node.answers.map((answer: any, index: number) => {
      const id = `${index.toString()}space`;
      const idCard = `${index.toString()}card`;
      return (
        <ContainerItem key={index}>
          <H2Sidebar key={id} id={id}>
            {answer.originTopic === undefined ? '' : answer.originTopic}
          </H2Sidebar>
          <UserCard key={idCard} answer={answer}></UserCard>
        </ContainerItem>
      );
    });
  } else {
    if (node.depth === 1) {
      const sonsLv2 = node._children;
      myArray = sonsLv2.map((sonLv2: any) => {
        const sonsLv3 = sonLv2._children;
        return sonsLv3.map((sonLv3: any) => {
          allAnswers = sonLv3.answers;
          return sonLv3.answers.map((answer: any, index: number) => {
            const id = `${index.toString()}space`;
            const idCard = `${index.toString()}card`;
            return (
              <ContainerItem key={index}>
                <H2Sidebar key={id} id={id}>
                  {answer.originTopic === undefined ? '' : answer.originTopic}
                </H2Sidebar>
                <UserCard key={idCard} answer={answer}></UserCard>
              </ContainerItem>
            );
          });
        });
      });
    }

    if (node.depth === 2) {
      const sonsLv3 = node._children;
      myArray = sonsLv3.map((sonLv3: any) => {
        allAnswers = sonLv3.answers;
        return sonLv3.answers.map((answer: any, index: number) => {
          const id = `${index.toString()}space`;
          const idCard = `${index.toString()}card`;
          return (
            <ContainerItem key={index}>
              <H2Sidebar key={id} id={id}>
                {answer.originTopic === undefined ? '' : answer.originTopic}
              </H2Sidebar>
              <UserCard key={idCard} answer={answer}></UserCard>
            </ContainerItem>
          );
        });
      });
    }
  }

  let categs;

  if (node.depth === 2) {
    categs = `${node.name}`;
  }

  if (node.depth === 3) {
    categs = `${node.parent.name}-${node.name}`;
  }

  const currentDate = new Date().toLocaleDateString();

  return (
    <>
      <Offcanvas
        style={{ width: '30%' }}
        show={show}
        key="end"
        onHide={handleClose}
        placement="end"
      >
        <Offcanvas.Header style={{ backgroundColor: '#ff780c' }}>
          <Offcanvas.Title style={{ color: '#FFFFFF', fontSize: '1.5vw' }}>
            {node.name}
          </Offcanvas.Title>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <PDFDownloadLink
              document={
                <PDFContent node={node} allAnswers={allAnswers} title={title} />
              }
              fileName={`${categs} ${currentDate}.pdf`}
            >
              <Button
                style={{
                  background: 'transparent',
                  borderColor: 'transparent',
                }}
              >
                <DownloadRoundedIcon fontSize="large" />
              </Button>
            </PDFDownloadLink>
            <Button
              onClick={handleClose}
              style={{
                height: '4vh',
                background: 'transparent',
                borderColor: 'transparent',
              }}
            >
              <CloseRoundedIcon fontSize="large" />
            </Button>
          </div>
        </Offcanvas.Header>
        <OffcanvasBody>{myArray}</OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default SideBar;
