/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import * as d3 from 'd3';
import React, { useCallback, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import copyData from 'src/Utils/functions/copyData';
import sortData from 'src/Utils/functions/sortData';
import centerImage from '../../assets/center_image.png';
import SideBar from '../sideBar/sideBar';

interface props {
  title: any;
  nodeClicked: any;
  setNodeClicked: any;
  data: any;
  setNode: any;
  showSideBar: boolean;
  setShowSideBar: any;
  answers: any;
  setAnswers: any;
  isExternalLink: boolean;
  showAnswers: boolean;
}

interface d3Start {
  root: any;
  source: any;
  diameter: any;
  height: any;
  i: any;
  duration: any;
  tree: any;
  diagonal: any;
  graphDiv: any;
  svg: any;
  g: any;
}

const RadialTree: React.FC<props> = ({
  title,
  nodeClicked,
  setNodeClicked,
  data,
  setNode,
  showSideBar,
  setShowSideBar,
  answers,
  setAnswers,
  showAnswers,
}) => {
  const [initialState, setInitialState] = useState<d3Start>();
  const [refreshGraph, setRefreshGraph] = useState<boolean>(false);

  const handleCloseSidebar = useCallback(() => {
    setShowSideBar(false);
  }, [setShowSideBar]);

  const click = useCallback(
    (node: any) => {
      if (node.depth === 0) {
        setRefreshGraph(true);
        const newD2: any = { ...node };
        setNode(newD2);
        return;
      }

      const totalAnswers = node.countPeopleAnswered
        ? node.countPeopleAnswered
        : node.countAnswers;

      // if the node have less then 10 answers, open the sideBar with the answers indenpendently of the node depth
      if (totalAnswers <= 10 || node.depth === 3) {
        setNodeClicked({ updated: false, node });
        setShowSideBar(true);
        setAnswers(node);

        const newD2: any = { ...node };
        setNode(newD2);
        if (initialState) {
          const newInitialState: d3Start = {
            ...initialState,
            source: node,
          };
          setInitialState(newInitialState);
        }
        return;
      }

      if (node.clicked === undefined || node.clicked === false) {
        // COLORING NODE WHEN CLICK ON IT =================================
        d3.selectAll('circle')
          .filter((c: any) => {
            return c === node;
          })
          .style('fill', '#ff780c');
        d3.selectAll('path.link')
          .filter((l: any) => {
            return l.target === node;
          })
          .style('stroke', '#ff780c');
        d3.selectAll('path.link')
          .filter((l: any) => {
            return l.target === node;
          })
          .style('stroke-width', 4);
        //= ================================================================
        node.clicked = true;
      } else {
        // GO BACK COLOR NODE WHEN CLICK ON IT =================================
        d3.selectAll('circle')
          .filter((c: any) => {
            return c === node;
          })
          .style('fill', '#FFFFFF');
        d3.selectAll('path.link')
          .filter((l: any) => {
            return l.target === node;
          })
          .style('stroke', '#FFFFFF');
        d3.selectAll('path.link')
          .filter((l: any) => {
            return l.target === node;
          })
          .style('stroke-width', 1);
        //= ================================================================
        node.clicked = false;
      }

      if (node.children) {
        const sons = node.children;
        sons.forEach((son: any) => {
          if (son.children) {
            son._children = son.children;
            son.children = null;
          }
        });
        node._children = node.children;
        node.children = null;
      } else {
        const brothers = node.parent.children;
        brothers.forEach((brother: any) => {
          if (brother.children) {
            // GO BACK COLOR NODE WHEN CLICK ON IT =================================
            d3.selectAll('circle')
              .filter((c: any) => {
                return c === brother;
              })
              .style('fill', '#FFFFFF');
            d3.selectAll('path.link')
              .filter((l: any) => {
                return l.target === brother;
              })
              .style('stroke', '#FFFFFF');
            d3.selectAll('path.link')
              .filter((l: any) => {
                return l.target === brother;
              })
              .style('stroke-width', 1);
            //= ================================================================
            brother.clicked = false;

            const sons = brother.children;
            sons.forEach((son: any) => {
              if (son.children) {
                son._children = son.children;
                son.children = null;
              }
              delete son.id;
            });

            brother._children = brother.children;
            brother.children = null;
          }
        });

        node.children = node._children;
        node._children = null;
      }

      const newD: any = { ...node };
      setNode(newD);

      if (initialState) {
        const newInitialState: d3Start = {
          ...initialState,
          source: node,
        };
        setInitialState(newInitialState);
      }

      const newD2: any = { ...node };
      setNode(newD2);
    },
    [setNode, initialState, setNodeClicked, setShowSideBar, setAnswers],
  );

  // initialize the variables
  useEffect(() => {
    d3.select('#svg-graph').remove();

    const diameter = 450;
    const height = 800;
    const i = 0;
    const duration = 400;
    const tree = d3.layout.tree().size([360, diameter / 2 - 80]);
    const diagonal = (d: any): string => {
      return `M${d.source.y},${d.source.x}C${(d.source.y + d.target.y) / 2},${
        d.source.x
      } ${(d.source.y + d.target.y) / 2},${d.target.x},${d.target.y},${
        d.target.x
      }`;
    };

    // d3.select('#main-content')
    //   .style('overflow', 'auto')
    //   .style('height', '100vh');
    const graphDiv = d3.select('#svg-div');

    const svg = graphDiv
      .append('svg')
      .attr('id', 'svg-graph')
      .attr('viewBox', '150 -70 900 900')
      // .style('display', 'flex')
      // .style('flex', 1);
      .attr('width', '100%');
    // .attr('height', height);

    const g = svg.append('g').attr('transform', `matrix(0.7,0,0,0.7,250,300)`);

    g.append('circle')
      .attr('id', 'dottleCircle')
      .attr('r', 113)
      .attr('stroke-dasharray', '4')
      .attr('stroke-width', 0.2)
      .attr('stroke', '#FFFFFF')
      .style('fill-opacity', '0');

    const root = copyData(data);
    root.x0 = height / 2;
    root.y0 = 0;

    const iniState: d3Start = {
      root,
      source: root,
      diameter,
      height,
      i,
      duration,
      tree,
      graphDiv,
      svg,
      g,
      diagonal,
    };

    setInitialState(iniState);
    if (refreshGraph) {
      setRefreshGraph(false);
    }

    // adding pan (drag) support
    let isDragging = false;
    let startX = 0;
    let startY = 0;
    const currentTransform = { x: 250, y: 300 };

    function onMouseDown(event: MouseEvent) {
      isDragging = true;
      startX = event.clientX;
      startY = event.clientY;
      event.preventDefault();
    }

    function onMouseMove(event: MouseEvent) {
      if (!isDragging) return;
      const dx = event.clientX - startX;
      const dy = event.clientY - startY;
      startX = event.clientX;
      startY = event.clientY;
      currentTransform.x += dx;
      currentTransform.y += dy;
      g.attr(
        'transform',
        `translate(${currentTransform.x},${currentTransform.y})`,
      );
    }

    function onMouseUp() {
      isDragging = false;
    }

    function onMouseLeave() {
      isDragging = false;
    }

    svg
      .node()
      .addEventListener('mousedown', event => onMouseDown(event as MouseEvent));
    svg
      .node()
      .addEventListener('mousemove', event => onMouseMove(event as MouseEvent));
    svg.node().addEventListener('mouseup', event => onMouseUp());
    svg.node().addEventListener('mouseleave', event => onMouseLeave());

    // zoom support
    const zoomFactor = 0.1;
    const minScale = 0.5; // set as you need
    const maxScale = 2; // set as you need
    let currentScale = 0.7;

    // zoom function
    function zoomed(event: any) {
      event.preventDefault();
      if (event.deltaY < 0) {
        currentScale = Math.min(currentScale + zoomFactor, maxScale);
      } else {
        currentScale = Math.max(currentScale - zoomFactor, minScale);
      }
      g.attr(
        'transform',
        `translate(${currentTransform.x},${currentTransform.y}) scale(${currentScale})`,
      );
    }

    // pan function (drag)
    function dragged(event: any) {
      currentTransform.x += event.movementX;
      currentTransform.y += event.movementY;
      g.attr(
        'transform',
        `translate(${currentTransform.x},${currentTransform.y}) scale(${currentScale})`,
      );
    }

    // adding native elements
    svg.node().addEventListener('wheel', zoomed);
    svg.node().addEventListener('mousedown', () => {
      svg.node().addEventListener('mousemove', dragged);
    });
    svg.node().addEventListener('mouseup', () => {
      svg.node().removeEventListener('mousemove', dragged);
    });

    // removing elements on useEffect clean
    return () => {
      svg.node().removeEventListener('wheel', zoomed);
      svg.node().removeEventListener('mousedown', () => {
        svg.node().removeEventListener('mousemove', dragged);
      });
      svg.node().removeEventListener('mouseup', () => {
        svg.node().removeEventListener('mousemove', dragged);
      });
      svg
        .node()
        .removeEventListener('mousedown', event =>
          onMouseDown(event as MouseEvent),
        );
      svg
        .node()
        .removeEventListener('mousemove', event =>
          onMouseMove(event as MouseEvent),
        );
      svg.node().removeEventListener('mouseup', event => onMouseUp());
      svg.node().removeEventListener('mouseleave', event => onMouseLeave());
    };
  }, [data, refreshGraph]);

  // update
  useEffect(() => {
    if (initialState) {
      const originalNodes = initialState?.tree.nodes(initialState.root);

      const nodes = sortData(originalNodes);

      const links = initialState?.tree.links(nodes);

      // Normalize for fixed-depth.
      nodes.forEach(function (d: any) {
        d.y = d.depth * 90;
      });

      // Update the nodes…
      const node = initialState?.g
        .selectAll('g.node')
        .data(nodes, function (d: any) {
          if (d.id) {
            ++initialState.i;
            return d.id;
          }
          d.id = ++initialState.i;
          return d.id;
        });

      // responsible to administrate and update the labels in all interactions with graph
      const arrayNodes = node[0];
      arrayNodes.forEach((no: any) => {
        const text1 = no.children[0];
        const text2 = no.children[1];
        const noData = no.__data__;

        if (initialState.source.depth !== 3) {
          if (noData.depth === initialState.source.depth) {
            if (initialState.source.children) {
              text1.innerHTML = '';
              text2.innerHTML = '';
            } else if (noData.splitedNamePart1) {
              text1.innerHTML = `(${noData.countAnswers}) - ${noData.splitedNamePart1}`;
              text2.innerHTML = noData.splitedNamePart2;
            } else {
              text1.innerHTML = `(${noData.countAnswers}) - ${noData.name}`;
              text2.innerHTML = '';
            }
          }

          if (noData.splitedNamePart1) {
            if (
              text1.innerHTML !== noData.splitedNamePart1 &&
              text1.innerHTML !== ''
            ) {
              if (noData.depth === 1) {
                text1.innerHTML = `${noData.splitedNamePart1}`;
              } else {
                text1.innerHTML = `(${noData.countAnswers}) - ${noData.splitedNamePart1}`;
              }
            }
          } else if (
            noData.depth !== initialState.source.depth &&
            (noData._children || noData.answers) &&
            text1.innerHTML !== noData.name
          ) {
            text1.innerHTML = `(${noData.countAnswers}) - ${noData.name}`;
          }

          if (noData.splitedNamePart2) {
            if (
              text2.innerHTML !== noData.splitedNamePart2 &&
              text2.innerHTML !== ''
            ) {
              text2.innerHTML = noData.splitedNamePart2;
            }
          } else {
            text2.innerHTML = '';
          }
        }
      });

      // Enter any new nodes at the parent's previous position.
      const nodeEnter = node
        .enter()
        .append('g')
        .attr('id', function (d: any) {
          return `${d.id.toString()}node`;
        })
        .attr('class', 'node')
        .attr('cursor', 'pointer')
        .on('click', click);

      nodeEnter
        .append('text')
        .attr('id', 'textPart1')
        .attr('x', function (d: any) {
          if (d.depth === 3) {
            if (d.answers.length >= 70) {
              d.dis_node = 45;
              return 45;
            }

            d.dis_node = 20;
            return 20;
          }
          if (d.depth === 1) {
            d.dis_node = 30;
            return 30;
          }
          return 20;
        })
        .attr('font-size', '20px')
        .attr('dy', '0em')
        .style('fill', '#E88500')
        .text(function (d: any) {
          if (d.depth === 0) {
            return '';
          }
          if (d.depth === 3) {
            if (d.splitedNamePart1) {
              return `(${d.countAnswers}) - ${d.splitedNamePart1}`;
            }
            return `(${d.countAnswers}) - ${d.name}`;
          }
          const brothers = d.parent.children
            ? d.parent.children
            : d.parent._children;
          let hideLabel = false;
          brothers.forEach((b: any) => {
            if (b.children) {
              hideLabel = true;
            }
          });

          if (!hideLabel) {
            if (d.depth === 1) {
              if (d.splitedNamePart1) {
                return `${d.splitedNamePart1}`;
              }
              return `${d.name}`;
            }
            if (d.splitedNamePart1) {
              return `(${d.countAnswers}) - ${d.splitedNamePart1}`;
            }
            return `(${d.countAnswers}) - ${d.name}`;
          }
          return '';
        });

      nodeEnter
        .append('text')
        .attr('id', 'textPart2')
        .attr('x', function (d: any) {
          if (d.depth === 3) {
            if (d.answers.length >= 70) {
              d.dis_node = 45;
              return 45;
            }

            d.dis_node = 20;
            return 20;
          }
          if (d.depth === 1) {
            d.dis_node = 30;
            return 30;
          }
          return 20;
        })
        .attr('font-size', '20px')
        .style('fill', '#E88500')
        .attr('dy', '1em')
        .text(function (d: any) {
          if (d.splitedNamePart2) {
            return `${d.splitedNamePart2}`;
          }
          return '';
        });

      nodeEnter
        .append('defs')
        .append('pattern')
        .attr('id', function () {
          return 'pic';
        })
        .attr('height', 1)
        .attr('width', 1)
        .attr('viewBox', '15 35 140 140')
        .append('image')
        .attr('xlink:href', function () {
          return centerImage;
        })
        .attr('height', '215')
        .attr('width', '188');

      nodeEnter
        .append('circle')
        .attr('r', 1e-6)
        .attr('id', function (d: any) {
          if (d.depth === 0) {
            return 'parent';
          }
          if (d.depth === 1) {
            return `${d.id.toString()}question`;
          }
          if (d.depth === 2) {
            return `${d.id.toString()}topic2`;
          }

          return `${d.id.toString()}topic1`;
        })
        // node edge
        .attr('stroke', function (d: any) {
          if (d.depth !== 0) {
            return '#5A1C61';
          }
          return '#FFFFFF';
        })
        .attr('stroke-width', 1.5);

      // Transition nodes to their new position.
      const nodeUpdate = node
        .transition()
        .duration(initialState?.duration)
        .attr('transform', function (d: any) {
          if (d.depth === 0) {
            return '';
          }

          if (d.depth === 1) {
            const brothers = d.parent.children;

            const middleElementPosition =
              brothers.length % 2 === 0
                ? brothers.length / 2
                : (brothers.length + 1) / 2;

            d.x = (d.id - middleElementPosition - 1) * 60;
            d.y = 200;
            return `translate(${d.y}, ${d.x})`;
          }

          if (d.depth === 2) {
            const brothers = d.parent.children;
            const childrenIds = brothers.map((a: any) => {
              return a.id;
            });
            const sortedIds = childrenIds.sort((a: any, b: any) =>
              a < b ? -1 : 1,
            );

            const middleElementPosition =
              brothers.length % 2 === 0
                ? brothers.length / 2
                : (brothers.length + 1) / 2;

            const middleElementId = sortedIds[middleElementPosition - 1];
            d.x = (d.id - middleElementId) * 50;
            d.y = 500;

            return `translate(${d.y}, ${d.x})`;
          }

          if (d.depth === 3) {
            const brothers = d.parent.children;
            const childrenIds = brothers.map((a: any) => {
              return a.id;
            });
            const sortedIds = childrenIds.sort((a: any, b: any) =>
              a < b ? -1 : 1,
            );
            const middleElementPosition =
              brothers.length % 2 === 0
                ? brothers.length / 2
                : (brothers.length + 1) / 2;

            console.log(middleElementPosition);
            const middleElementId = sortedIds[middleElementPosition - 1];
            d.x = (d.id - middleElementId) * 50;
            d.y = 700;

            return `translate(${d.y}, ${d.x})`;
          }
          return '';
        });

      nodeUpdate
        .select('circle')
        .attr('r', function (d: any) {
          if (d.depth === 0) {
            d.r_aux = 70;
            return 70;
          }
          if (d.depth === 3) {
            const brothers = d.parent.children;
            const sorted = brothers.sort((a: any, b: any) =>
              a.countAnswers > b.countAnswers ? -1 : 1,
            );
            const maxAnswers = sorted[0].countAnswers;
            const minAnswers = sorted[sorted.length - 1].countAnswers;
            const minR = 7;
            const maxR = 14;

            const a = (maxR - minR) / (maxAnswers - minAnswers);
            const b = minR - minAnswers * a;

            const newR = a * d.countAnswers + b;
            return d.countAnswers === maxAnswers
              ? maxR
              : d.countAnswers === minAnswers
                ? minR
                : newR * 1.2;
          }

          if (d.depth === 2) {
            const brothers = d.parent.children;
            const sorted = brothers.sort((a: any, b: any) =>
              a.countAnswers > b.countAnswers ? -1 : 1,
            );
            const maxAnswers = sorted[0].countAnswers;
            const minAnswers = sorted[sorted.length - 1].countAnswers;
            const minR = 8;
            const maxR = 17;

            const a = (maxR - minR) / (maxAnswers - minAnswers);
            const b = minR - minAnswers * a;

            const newR = a * d.countAnswers + b;
            return d.countAnswers === maxAnswers
              ? maxR
              : d.countAnswers === minAnswers
                ? minR
                : newR * 1.2;
          }

          if (d.depth === 1) {
            const brothers = d.parent.children;
            const maxAnswers = brothers[0].countPeopleAnswered;
            const minAnswers =
              brothers[brothers.length - 1].countPeopleAnswered;
            const minR = 10;
            const maxR = 19;

            const a = (maxR - minR) / (maxAnswers - minAnswers);
            const b = minR - minAnswers * a;

            const newR = a * d.countPeopleAnswered + b;
            return d.countPeopleAnswered === maxAnswers
              ? maxR
              : d.countPeopleAnswered === minAnswers
                ? minR
                : newR * 1.2;
          }

          d.r_aux = 13;
          return 13;
        })
        .style('fill', function (d: any) {
          if (d.depth === 0) {
            return 'url(#pic)';
          }

          if (d.depth === 1) {
            if (d._children) {
              return '#FFFFFF';
            }
            return '#E88500';
          }

          if (d.depth === 2) {
            if (d._children) {
              return '#FFFFFF';
            }
            return '#E88500';
          }

          if (d.depth === 3) {
            return '#FFFFFF';
          }

          const color = nodeUpdate
            .filter(function (n: any) {
              return n === d;
            })
            .select('circle')[0][0].style.fill;
          return color;
        });

      nodeUpdate
        .select('#textPart1')
        .style('fill-opacity', 1)
        .style('text-anchor', function (d: any) {
          return 'start';
        })
        .attr('transform', function (d: any) {
          return 'translate(0)';
        });

      nodeUpdate
        .select('#textPart2')
        .style('fill-opacity', 1)
        .style('text-anchor', function (d: any) {
          return 'start';
        })
        .attr('transform', function (d: any) {
          return 'translate(0)';
        });

      nodeUpdate
        .select('#countAnswersText')
        .style('fill-opacity', 1)
        .attr('transform', function (d: any) {
          return 'translate(0)';
        });

      const nodeExit = node
        .exit()
        .transition()
        .duration(initialState?.duration)
        .remove();

      nodeExit.select('circle').attr('r', 1e-6);

      nodeExit.select('text').style('fill-opacity', 1e-6);

      // Update the links…
      const link = initialState?.g
        .selectAll('path.link')
        .data(links, function (d: any) {
          return d.target.id;
        });

      // Enter any new links at the parent's previous position.
      link
        .enter()
        .insert('path', 'g')
        .attr('class', 'link')
        .attr('d', function () {
          const o = {
            x: initialState.source.x0 + 90,
            y: initialState.source.y0 + 20,
          };
          return initialState?.diagonal({ source: o, target: o }, 0);
        });

      // Transition links to their new position.
      link
        .transition()
        .duration(initialState?.duration)
        .attr('d', function (d: any) {
          if (d.source.depth === 0) {
            const ori = { x: d.source.y, y: d.source.y + 70 };
            const des = { x: d.target.x, y: d.target.y };
            return initialState?.diagonal({ source: ori, target: des }, 100);
          }

          if (d.source.depth === 1) {
            const ori = { x: d.target.x, y: d.target.y };
            const des = { x: d.source.x, y: d.source.y };
            return initialState?.diagonal({ source: ori, target: des }, 100);
          }

          if (d.source.depth === 2) {
            const ori = { x: d.source.x, y: d.source.y };
            const des = { x: d.target.x, y: d.target.y };
            return initialState?.diagonal({ source: ori, target: des }, 0);
          }

          return '';
        })
        .attr('fill', 'none')
        .style('stroke', function (d: any) {
          // get the currenct color of link
          if (d.target.clicked === undefined || d.target.clicked === false) {
            return '#FFFFFF';
          }
          // get the currenct color of link
          const color = link.filter(function (l: any) {
            return l.target === d.target;
          })[0][0].style.stroke;
          return color;
        })
        .style('stroke-width', function (d: any) {
          if (d.target.clicked === undefined) {
            return 1;
          }

          // get the currenct stroke-width of link
          const { strokeWidth } = link.filter(function (l: any) {
            return l.target === d.target;
          })[0][0].style;
          return strokeWidth;
        });

      // Transition exiting nodes to the parent's new position.
      link
        .exit()
        .transition()
        .duration(initialState?.duration)
        .attr('d', function () {
          const o = {
            x: initialState.source.x + 90,
            y: initialState.source.y + 30,
          };
          return initialState?.diagonal({ source: o, target: o }, 0);
        })
        .remove();

      // Stash the old positions for transition.
      nodes.forEach(function (d: any) {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    }
    d3.select('frameElement').style('height', '200px');
  }, [click, initialState, nodeClicked]);

  return (
    <>
      {showAnswers && (
        <SideBar
          title={title}
          show={showSideBar}
          handleClose={handleCloseSidebar}
          node={answers}
        ></SideBar>
      )}
    </>
  );
};

export default RadialTree;
